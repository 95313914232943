/**
 * @file Provides the authentication store and related actions for managing user authentication.
 * @module auth
 */
import { createStore } from 'zustand-x';

// import { PermissionsAbility } from '../context/PermissionsContext';
import { queryClient } from '@webfx/core-web/src/api';
import feathers from '@webfx/core-web/src/services/feathersClient';

/**
 * The AuthStore object represents the authentication store. To be used in other stores.
 */
export const AuthStore = createStore('auth')(
  {
    user: null,
    loggedInUser: null,
    publicToken: null,
  },
  {
    persist: {
      enabled: true,
      version: 2,
    },
  }
)
  .extendSelectors((state, get) => ({
    accessToken: () => {
      return localStorage.getItem('accessToken');
    },

    isAdmin: () => {
      const user = get.user();
      if (!user) {
        return false;
      }

      if (['owner', 'admin'].includes(user.type)) {
        return true;
      }
    },

    isGuest: () => {
      const user = get.user();
      if (!user) {
        return true;
      }
      return user.email === 'guest@webfx.com';
    },
  }))
  .extendActions((set, get) => ({
    /**
     * Login a user
     * @param {*} payload authentication payload
     * @returns
     */
    login: async (payload) => {
      const authentication = await feathers().authenticate(payload);

      set.user(authentication.user);

      if (payload.strategy !== 'apikey' || payload.forceLogin) {
        set.loggedInUser(authentication.user);
      }

      // pre-fetch permissions
      queryClient.prefetchQuery(['permissions', authentication.user.userId]);

      return authentication;
    },
    /**
     * Logout a user
     * @returns
     */
    logout: async () => {
      try {
        await feathers().logout();
        localStorage.removeItem('originalAccessToken');
        // eslint-disable-next-line no-empty -- catch errors thrwon by feathers
      } catch (err) {}

      set.user(null);
      set.loggedInUser(null);
      set.publicToken(null);
    },

    /**
     * Switch back to the originally user that logged in
     * @returns
     */
    switchBackToLoggedInUser: async () => {
      localStorage.setItem('accessToken', localStorage.getItem('originalAccessToken'));
      localStorage.removeItem('originalAccessToken');
      queryClient.cancelQueries();
      set.user(get.loggedInUser());
      window.location.reload();
    },
  }))
  // This method is in it's own extend so that it can
  // call the original login method
  .extendActions((set, get) => ({
    /**
     * Login as another user
     * used in global permissions as part of view as user feature
     * @param {*} payload
     * @returns
     */
    loginAsUser: async (payload) => {
      if (!get.isAdmin()) {
        return;
      }

      localStorage.setItem('originalAccessToken', localStorage.getItem('accessToken'));

      // eslint-disable-next-line no-unused-vars -- may be used in future
      const auth = await set.login({
        strategy: 'apikey',
        apikey: payload,
        impersonating: true,
        impersonatingBy: get.user().userId,
      });
      queryClient.invalidateQueries();
    },
  }));
