import React from 'react';
import { Nav, Tab } from 'react-bootstrap';
import classnames from 'classnames';
import { ContentContainer, Spinner, Icon, Paper, ErrorPage } from '@webfx/core-web';
import {
  useAuth,
  useFlags,
  useDocumentTitle,
  useNutshellAuth,
  useNotifications,
} from '@webfx/web-hooks';
import { NutshellEmbed } from '@webfx/web-ui-connected';
import Profile from './Profile';
import Tokens from './Tokens';

import styles from './MyInfo.module.css';

const EmailNotifications = React.lazy(() => import('./EmailNotifications'));

/**
 *
 */
const MyInfo = () => {
  const { user = {}, isAdmin } = useAuth();
  const flags = useFlags();
  const canViewNotifications = flags.iamEmailpreferences;
  useDocumentTitle('My Info | RevenueCloudFX');

  return (
    <ContentContainer>
      <Paper>
        <div className="d-flex">
          <Tab.Container id="view-nav" defaultActiveKey="profile">
            <aside className={classnames(['col-3', styles.sidebar])}>
              <Nav variant="pills" className="flex-column">
                <NavItem label="Profile" icon="person" eventKey="profile" />
                <NavItem label="API Keys" icon="vpn_key" eventKey="tokens" />
                {isAdmin ? (
                  <NavItem label="SchedulerFX" icon="mood" eventKey="schedulerFx" />
                ) : null}
                {canViewNotifications ? (
                  <NavItem label="Email Notifications" icon="email" eventKey="emailNotifications" />
                ) : null}
              </Nav>
            </aside>
            <Tab.Content className="flex-fill overflow-auto pl-3">
              <Tab.Pane eventKey="profile">
                <Profile />
              </Tab.Pane>
              <Tab.Pane eventKey="tokens">
                <Tokens />
              </Tab.Pane>
              {isAdmin ? (
                <Tab.Pane unmountOnExit eventKey="schedulerFx">
                  <FXerNutshellEmbedframe />
                </Tab.Pane>
              ) : null}
              {canViewNotifications ? (
                <Tab.Pane eventKey="emailNotifications">
                  <EmailNotifications viewingUser={user} />
                </Tab.Pane>
              ) : null}
            </Tab.Content>
          </Tab.Container>
        </div>
      </Paper>
    </ContentContainer>
  );
};

const FXerNutshellEmbedframe = () => {
  const embedUrl = 'https://nutshell.webfx.com/embed/scheduler';
  const auth = useNutshellAuth({ authFor: 'embed', instanceId: '365724' });
  const { token } = auth?.data || {};
  const { toast } = useNotifications();
  const isErrorNotificationShown = React.useRef(false);
  if (auth.isFetched && !token) {
    if (!isErrorNotificationShown.current) {
      toast.error(
        'Your Nutshell account is not available. Please contact your account manager for assistance.'
      );
      isErrorNotificationShown.current = true;
    }

    return (
      <ErrorPage title={'404'} subtitle={'Your Nutshell account is not available'}>
        {<p>Please contact your account manager for assistance.</p>}
      </ErrorPage>
    );
  }

  if (!token) {
    return (
      <div className="mt-5 d-flex justify-content-center">
        <Spinner />
      </div>
    );
  }
  return <NutshellEmbed token={token} embedURL={embedUrl} />;
};

const NavItem = ({ label, icon = '', eventKey, ...passThroughProps }) => {
  const [iconType] = icon.split('_outlined');
  const outlined = icon.endsWith('_outlined');
  return (
    <Nav.Item>
      {
        <Nav.Link
          className={styles.navLink}
          data-fx-name={eventKey}
          eventKey={eventKey}
          {...passThroughProps}
        >
          {iconType ? (
            <Icon type={iconType} outlined={!!outlined} className={styles.navIcon} />
          ) : null}
          <span>{label}</span>
        </Nav.Link>
      }
    </Nav.Item>
  );
};
export default MyInfo;
