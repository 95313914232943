import { useEffect } from 'react';
import { datadogRum } from '@datadog/browser-rum';
import { feathers } from '@webfx/core-web';
import { useLocation } from 'react-router-dom';
import useAuth from './useAuth';
import useFlags from './useFlags';

const routeModuleMap = {
  marketingcloudfx: 'mcfx',
  'client-permissions': 'iam',
  contentgenius: 'contentgenius',
  playbookfx: 'playbook',
  nutshell: 'nutshell',
  teamai: 'teamai',
  'help-center': 'helpcenter',
  // twfx has multiple root paths to consider
  calendar: 'twfx',
  conversation: 'twfx',
  dashboard: 'twfx',
  milestones: 'twfx',
  posts: 'twfx',
  projects: 'twfx',
  'todo-lists': 'twfx',
  'todo-lists-plus': 'twfx',
  todos: 'twfx',
  templates: 'twfx',
};

// For the TWFX SaaS report, we need to identify v1 vs. v2 products and features
const twfxProductMapping = {
  calendar: 'twfxv1',
  conversation: 'twfxv1',
  dashboard: 'twfxv1',
  milestones: 'twfxv1',
  posts: 'twfxv1',
  projects: (segments) => {
    if (
      !segments[2] ||
      segments[2] === 'dashboard' ||
      segments[2] === 'todos' ||
      segments[2] === 'overview'
    ) {
      return 'twfxv1';
    } else if (segments[2] === 'posts') {
      return 'twfxv1';
    } else if (segments[2] === 'todo-lists') {
      return 'twfxv1';
    } else if (segments[2] === 'lists-plus') {
      return 'twfxv2';
    } else if (segments[2] === 'todo-table') {
      return 'twfxv2';
    } else if (segments[2] === 'milestones') {
      return 'twfxv1';
    } else if (segments[2] === 'files') {
      return 'twfxv1';
    } else if (segments[2] === 'history') {
      return 'twfxv1';
    } else if (segments[2] === 'settings') {
      return 'twfxv1';
    } else if (segments[2] === 'people-and-permissions') {
      return 'twfxv1';
    } else if (segments[2] === 'search') {
      return 'twfxv1';
    } else if (segments[2] === 'collaboration') {
      return 'twfxv1';
    } else {
      // Anything else
      return 'uncategorized';
    }
  },
  'todo-lists': 'twfxv1',
  'todo-lists-plus': 'twfxv2',
  todos: (segments) => {
    if (segments[1]) {
      // Single todo
      return 'twfxv1';
    }
    // Dashboard all todos
    return 'twfxv1';
  },
  templates: (segments) => {
    if (segments[1] === 'projects' || segments[1] === 'todolists') {
      return 'twfxv2';
    }
    return 'twfxv1';
  },
};
const twfxFeatureMapping = {
  calendar: 'allMilestones',
  conversation: 'allMessages',
  dashboard: 'dashboard',
  milestones: 'singleMilestone',
  posts: 'singleMessage',
  templates: 'templates',
  projects: (segments) => {
    if (
      !segments[2] ||
      segments[2] === 'dashboard' ||
      segments[2] === 'todos' ||
      segments[2] === 'overview'
    ) {
      return 'projectOverview';
    } else if (segments[2] === 'posts') {
      return 'projectMessages';
    } else if (segments[2] === 'todo-lists') {
      return 'projectLists';
    } else if (segments[2] === 'lists-plus') {
      return 'projectListsPlus';
    } else if (segments[2] === 'todo-table') {
      return 'projectTodoTable';
    } else if (segments[2] === 'milestones') {
      return 'projectMilestones';
    } else if (segments[2] === 'files') {
      return 'projectUploads';
    } else if (segments[2] === 'history') {
      return 'projectHistory';
    } else if (segments[2] === 'settings') {
      return 'projectSettings';
    } else if (segments[2] === 'people-and-permissions') {
      return 'projectPermissions';
    } else if (segments[2] === 'search') {
      return 'projectSearch';
    } else if (segments[2] === 'collaboration') {
      return 'projectCollaboration';
    } else {
      // Anything else
      return 'uncategorized';
    }
  },
  'todo-lists': 'legacyList',
  'todo-lists-plus': 'listPlus',
  todos: (segments) => {
    if (segments[1]) {
      // Single todo
      return 'singleTodo';
    }
    // Dashboard all todos
    return 'allTodos';
  },
};

const FX_PROJECTS_USER_ID = 11332601;

/**
 *
 * @param {string} tools
 */
export function useMeasure(tools) {
  const { user, isAdmin } = useAuth();
  const { pathname } = useLocation();
  const flags = useFlags();

  useEffect(() => {
    switch (tools) {
      case 'pendo':
        setPendoUser(user, isAdmin, flags);
        break;
      case 'datadog':
        setRumUser(user, isAdmin);
        break;
      default:
        setPendoUser(user, isAdmin, flags);
        setRumUser(user, isAdmin);
    }
  }, [user, tools]);

  useEffect(() => {
    if (pathname) {
      adjustRumContext(pathname, isAdmin);
    }
  }, [pathname, isAdmin]);

  useEffect(() => {
    setRumUser(user, isAdmin);
  }, [user, isAdmin]);
}

/**
 *
 */
export function initializeDatadog() {
  if (window.Cypress) {
    return;
  }

  datadogRum.init({
    applicationId: '6b21282a-202f-4fff-8a41-93d26135e559',
    clientToken: 'pub0d149f2058bcf2083a1b384dca713be2',
    site: 'datadoghq.com',
    service: `mcfx-v5`,
    version: process.env.POI_APP_BUILD_VERSION || `0.0.1-${process.env.NODE_ENV}`,
    env: process.env.POI_APP_BUILD_ENV || process.env.NODE_ENV || 'development',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    allowedTracingOrigins: [/https:\/\/.*\.webfx\.com/],
    beforeSend: (event) => {
      if (
        event.type === 'error' &&
        (event.error.message.toLowerCase().includes('illegal invocation') ||
          event.error.message
            .toLowerCase()
            .includes('resizeobserver loop completed with undelivered notifications') ||
          event.error.type === 'Forbidden' ||
          event.error.type === 'MethodNotAllowed')
      ) {
        return false;
      }
    },
  });
}

function adjustRumContext(pathname) {
  if (window.Cypress) {
    return;
  }

  if (!pathname) {
    datadogRum.removeGlobalContextProperty('module');
    datadogRum.removeGlobalContextProperty('product');
    datadogRum.removeGlobalContextProperty('feature');
    return;
  }

  const segments = pathname.split('/').filter((s) => s);
  const mod = routeModuleMap[segments[0]] || 'uncategorized';

  datadogRum.setGlobalContextProperty('module', mod);
  datadogRum.setGlobalContextProperty('product', mod);

  if (mod === 'mcfx' && segments[2]) {
    datadogRum.setGlobalContextProperty('feature', segments[2]);
  } else if (mod === 'twfx') {
    let product, feature;
    if (typeof twfxProductMapping[segments[0]] === 'function') {
      product = twfxProductMapping[segments[0]](segments);
    } else {
      product = twfxProductMapping[segments[0]] ?? 'uncategorized';
    }
    if (typeof twfxFeatureMapping[segments[0]] === 'function') {
      feature = twfxFeatureMapping[segments[0]](segments);
    } else {
      feature = twfxFeatureMapping[segments[0]] ?? 'uncategorized';
    }
    datadogRum.setGlobalContextProperty('product', product);
    datadogRum.setGlobalContextProperty('feature', feature);
  } else {
    datadogRum.removeGlobalContextProperty('feature');
  }
}

function setRumUser(user, isAdmin) {
  if (window.Cypress) {
    return;
  }
  if (user) {
    datadogRum.setUser({
      id: user.userId,
      name: `${user.firstName ?? ''} ${user.lastName ?? ''}`,
      email: user.email,
      type: user.type,
      isAdmin,
    });
    datadogRum.setGlobalContextProperty('userId', user.userId);
  }
  datadogRum.setGlobalContextProperty('isAdmin', isAdmin);
}

async function setPendoUser(user, isAdmin, flags = {}) {
  if (window.Cypress) {
    return;
  }

  if (process.env.NODE_ENV !== 'production') {
    return;
  }

  if (user?.userId === FX_PROJECTS_USER_ID) {
    return;
  }

  if (!window.pendo) {
    return;
  }

  let accountData = {};
  try {
    if (user?.userId) {
      accountData = await feathers().service(`/users/${user.userId}/tracking-info`).find();
    }
  } catch (e) {
    console.error(e);
  }

  const isGuest = (!user && !isAdmin) || user.userId === 3;
  if (isGuest) {
    accountData.account = {};
  }

  if (!window.pendo.isReady?.()) {
    window.pendo.initialize({
      visitor: {
        app_build_number: process.env.POI_APP_BB_BUILD_NUMBER,
        app_version:
          typeof window.FX_APP_VERSION !== 'object' ? window.FX_APP_VERSION : 'development',
        ...accountData.user,
        ...flags,
      },
      account: accountData.account,
    });
  } else {
    window.pendo.updateOptions({
      visitor: {
        app_build_number: process.env.POI_APP_BB_BUILD_NUMBER,
        app_version:
          typeof window.FX_APP_VERSION !== 'object' ? window.FX_APP_VERSION : 'development',
        ...accountData.user,
        ...flags,
      },
      account: accountData.account,
    });
  }
}
