import { api } from '@webfx/core-web';
import { useActiveSite } from '@webfx/web-hooks';

const useDeals = (query = {}) => {
  const { siteId } = useActiveSite();
  return api.useQuery(['revops/deals', { siteId, ...query }], {
    enabled: !!siteId,
  });
};

export default useDeals;
