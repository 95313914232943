// PDL Job titles prior to v27.1
const jobDepartments = [
  { label: 'Customer Service', value: 'customer_service' },
  { label: 'Design', value: 'design' },
  { label: 'Education', value: 'education' },
  { label: 'Engineering', value: 'engineering' },
  { label: 'Finance', value: 'finance' },
  { label: 'Health', value: 'health' },
  { label: 'Human Resources', value: 'human_resources' },
  { label: 'Legal', value: 'legal' },
  { label: 'Marketing', value: 'marketing' },
  { label: 'Media', value: 'media' },
  { label: 'Operations', value: 'operations' },
  { label: 'Public Relations', value: 'public_relations' },
  { label: 'Real Estate', value: 'real_estate' },
  { label: 'Sales', value: 'sales' },
  { label: 'Trades', value: 'trades' },
];

// PDL Job titles after v27.1
export const jobDepartmentsV2 = [
  { label: 'Advisory', value: 'advisory' },
  { label: 'Analyst', value: 'analyst' },
  { label: 'Creative', value: 'creative' },
  { label: 'Education', value: 'education' },
  { label: 'Engineering', value: 'engineering' },
  { label: 'Finance', value: 'finance' },
  { label: 'Fulfillment', value: 'fulfillment' },
  { label: 'Health', value: 'health' },
  { label: 'Hospitality', value: 'hospitality' },
  { label: 'Human Resources', value: 'human_resources' },
  { label: 'Legal', value: 'legal' },
  { label: 'Manufacturing', value: 'manufacturing' },
  { label: 'Marketing', value: 'marketing' },
  { label: 'Operations', value: 'operations' },
  { label: 'Partnerships', value: 'partnerships' },
  { label: 'Product', value: 'product' },
  { label: 'Professional Service', value: 'professional_service' },
  { label: 'Public Service', value: 'public_service' },
  { label: 'Research', value: 'research' },
  { label: 'Sales', value: 'sales' },
  { label: 'Sales Engineering', value: 'sales_engineering' },
  { label: 'Support', value: 'support' },
  { label: 'Trade', value: 'trade' },
  { label: 'Unemployed', value: 'unemployed' },
];

export default jobDepartments;
