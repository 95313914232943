import React, { useState, useEffect, useImperativeHandle } from 'react';
import { nanoid } from 'nanoid';
import DropzoneFilesUpload from './DropzoneFilesUpload';
import FileThumbnail from './FileThumbnail';

const Dropzone = React.forwardRef(
  (
    {
      formObject = {},
      onFilesProcessed,
      onFilesRemoved,
      onFilesDropped = () => {},
      onFilesRejected = () => {},
      onFilesUploaded = () => {},
      placeHolder = 'DRAG AND DROP FILES HERE | SELECT FILE',
      maxFiles = 0,
      allowedFilesType,
      title = 'Upload a file',
      avatarStyle,
      showImagePreviewOnDragDiv = false,
      avatarUrl,
      disablePrivate = false,
      initialFiles = [],
      disabled = false,
    },
    ref
  ) => {
    if (avatarUrl) {
      const hasAvatar = avatarUrl.includes('/assets/images/avatar.png') ? false : true;

      hasAvatar &&
        initialFiles.push({
          avatarUrl,
        });
    }

    const [avatar, setAvatarUrl] = useState(avatarUrl);
    const [files, setFiles] = useState(initialFiles);

    const handleSetUploadedFile = ({ file, index }) => {
      if (typeof onFilesProcessed === 'function') {
        onFilesProcessed({ file, index });
      }
    };

    useImperativeHandle(ref, () => ({
      removeFiles() {
        setFiles([]);
      },
    }));

    const handleImageUpload = async (acceptedFiles) => {
      const transformAcceptedFiles = acceptedFiles.map((file) => {
        return Object.assign(file, {
          status: 'pending',
          id: nanoid(),
          preview: URL.createObjectURL(file),
          fileData: file,
        });
      });

      const updatedFiles = files.concat(transformAcceptedFiles);
      setAvatarUrl(null);
      setFiles(updatedFiles);

      onFilesUploaded();
    };

    const handleRemoveFile = ({ removedFile, index }) => {
      if (typeof onFilesRemoved === 'function') {
        onFilesRemoved({ file: removedFile, index });
      }
    };

    useEffect(
      () => () => {
        // Make sure to revoke the data uris to avoid memory leaks
        files.forEach((file) => URL.revokeObjectURL(file.preview));
      },
      [files]
    );

    return (
      <div ref={ref} data-fx-name="dropFilecontainer">
        {title ? <div>Upload a file</div> : null}

        <DropzoneFilesUpload
          allowedFilesType={allowedFilesType}
          maxFiles={maxFiles}
          placeHolder={placeHolder}
          handleImageUpload={handleImageUpload}
          handleFilesDropped={onFilesDropped}
          handleFilesRejected={onFilesRejected}
          handleFilesUploaded={onFilesUploaded}
          avatarStyle={avatarStyle}
          disabled={disabled}
          showImagePreviewOnDragDiv={showImagePreviewOnDragDiv}
        />
        <div style={{ float: 'left', width: '100%' }}>
          {files.map((file, index) => (
            <FileThumbnail
              key={index}
              handleRemoveFile={handleRemoveFile}
              formObject={formObject}
              file={file}
              index={index}
              handleSetUploadedFile={handleSetUploadedFile}
              showImagePreviewOnDragDiv={showImagePreviewOnDragDiv}
              avatar={avatar}
              disablePrivate={disablePrivate}
              disabled={disabled}
            />
          ))}
        </div>
      </div>
    );
  }
);

export default Dropzone;
