import React, { useState, useEffect } from 'react';

import { useStoreState } from 'easy-peasy';
import { Spinner, Icon, FileImg } from '@webfx/core-web';
import { useQueryClient } from 'react-query';

import axios from 'axios';
import { getAccessToken } from '@webfx/core-web/src/services/auth';

import useProjectUploadsMutator from '@webfx/teamwork-web/src/hooks/useProjectUploadsMutator';
import { getFileUrl } from '../../utils-deprecated/files';
import thumbStyle from './thumbnail.style';
import style from './DropzoneFilesUpload.module.css';
import PrivateCheckbox from './PrivateCheckbox';
import AvatarThumbnail from './AvatarThumbnail';
import handleUpload from './helpers/handleUpload';
import updateProjectPrivateStatus from './helpers/updateProjectPrivateStatus';
import setProjectIdForUpload from './helpers/setProjectIdForUpload';
import removeFile from './helpers/removeFile';

const uploadFile = ({ file, apiUpload }) => {
  const accessToken = getAccessToken();
  const formData = new FormData();
  formData.append('file', file.fileData, file.name);
  return axios.post(apiUpload, formData, {
    headers: {
      Authorization: accessToken,
    },
  });
};

/**
 *
 * @param root0
 * @param root0.file
 * @param root0.index
 * @param root0.handleSetUploadedFile
 * @param root0.handleRemoveFile
 * @param root0.formObject
 * @param root0.showImagePreviewOnDragDiv
 * @param root0.avatar
 * @param root0.disablePrivate
 * @param root0.disabled
 */
const FileThumbnail = ({
  file: thumbnail,
  index,
  handleSetUploadedFile,
  handleRemoveFile,
  formObject,
  showImagePreviewOnDragDiv,
  avatar = null,
  disablePrivate,
  disabled,
}) => {
  if (thumbnail.upload) {
    thumbnail = { ...thumbnail, ...thumbnail?.upload };
  }

  const [file, setFile] = useState(thumbnail);
  const [privateFile, setPrivateFile] = useState(false);
  const [privateFileLoading, setPrivateFileLoading] = useState(false);
  const apiUpload = useStoreState((state) => state.config.config('apiUpload'));
  const queryClient = useQueryClient();
  const projectUploadsMutator = useProjectUploadsMutator();

  const invalidateQuery = ({ companyId }) => {
    queryClient.invalidateQueries('uploads');
    if (companyId) {
      queryClient.invalidateQueries('companies');
    }
  };

  useEffect(() => {
    if (file && file.status == 'pending') {
      handleUpload({
        file,
        apiUpload,
        uploadsMutator: projectUploadsMutator,
        handleSetUploadedFile,
        setProjectIdForUpload,
        setFile,
        uploadFile,
        formObject,
        index,
        invalidateQuery,
      });
    }
  }, []);

  const onFileRemove = ({ removedFile, fileSet, fileIndex }) => {
    handleRemoveFile({ removedFile, fileIndex });
    fileSet(null);
  };

  const handleRemove = () => {
    setFile({
      ...file,
      status: 'deleting',
    });

    removeFile({ mutator: projectUploadsMutator, onFileRemove, setFile, index })({
      file,
      uploadId: file.uploadId,
      formObject,
    });
  };

  const handlePrivate = () => {
    const privateStatus = privateFile ? false : true;
    setPrivateFile(privateStatus);
    setPrivateFileLoading(true);

    updateProjectPrivateStatus({ mutator: projectUploadsMutator, setPrivateFileLoading })({
      uploadId: file.uploadId,
      privateStatus,
    });
  };

  const getFileName = (name) => {
    const limit = 50;
    if (name.length > limit) {
      return `${name.substr(0, limit)}....`;
    }

    return name;
  };

  const handleRetry = () => {
    const { status, ...data } = file;
    setFile({
      status: 'pending',
      ...data,
    });
    handleUpload({
      uploadsMutator: projectUploadsMutator,
      file,
      apiUpload,
      handleSetUploadedFile,
      setProjectIdForUpload,
      setFile,
      uploadFile,
      queryClient,
    });
  };

  const handleRemoveAvatar = () => {
    const obj = {
      file,
      formObject,
      avatarUrl: avatar,
      isAvatar: showImagePreviewOnDragDiv,
    };

    //check if avatar url has upload id
    let id = null;
    if (avatar) {
      id = parseInt(avatar.split('uploads/').pop());
    }

    setFile({
      ...file,
      status: 'deleting',
    });
    removeFile({ mutator: projectUploadsMutator, onFileRemove, setFile, index })(obj);

    if (!id) {
      setFile(null);
    }
  };

  if (!file) {
    return <></>;
  }

  if (showImagePreviewOnDragDiv) {
    return (
      <AvatarThumbnail
        avatar={avatar}
        handleRemoveAvatar={handleRemoveAvatar}
        file={file}
        handleRemove={handleRemove}
        handleRetry={handleRetry}
        disabled={disabled}
      />
    );
  }

  return (
    <div style={thumbStyle.thumbWrapper}>
      <div className={style.overlayNameDiv}>
        {getFileName(file.name)} {file.status}
      </div>

      {file.status == 'failed' ? (
        <div className={style.overlayFailedAlertMessage}>
          File upload failed click on retry icon
        </div>
      ) : null}
      <div style={thumbStyle.thumbDiv}>
        {file.uploadId && file.status != 'deleting' ? (
          <div onClick={handleRemove} className={style.overlayRemoveDiv}>
            <div className={style.overlayRemoveDivIcon}>
              <Icon style={thumbStyle.removeIcon}>highlight_off</Icon>
            </div>
          </div>
        ) : null}

        {file.status == 'failed' ? (
          <div onClick={handleRetry} className={style.overlayRemoveDiv}>
            <div className={style.overlayRemoveDivIcon}>
              <Icon style={thumbStyle.retryIcon}>restart_alt</Icon>
            </div>
          </div>
        ) : null}

        <div style={thumbStyle.thumbInner}>
          {file.type.includes('image/') ? (
            <img
              src={file.uploadId ? getFileUrl(file.uploadId) : file.preview}
              style={thumbStyle.img}
            />
          ) : null}
          {!file.type.includes('image/') ? (
            <FileImg
              imgClassName={style.fileImage}
              style={{ width: '60px' }}
              type={file.name.split('.').pop()}
            />
          ) : null}
        </div>

        {(!file.uploadId || file.status === 'deleting') && file.status !== 'failed' ? (
          <div className={style.overlayDiv}>
            <div className={style.spinnerDiv}>
              <Spinner className={style.spinnerDropzone} />
            </div>
          </div>
        ) : null}
      </div>

      {!disablePrivate && file.uploadId ? (
        <PrivateCheckbox
          privateFile={privateFile}
          handlePrivate={handlePrivate}
          privateFileLoading={privateFileLoading}
        />
      ) : null}
    </div>
  );
};

export default FileThumbnail;
