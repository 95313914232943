import React, { Suspense, useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import classnames from 'classnames';
import Alert from 'react-bootstrap/Alert';

import { Snackbar, Typography, Svg, Link, IconLabel } from '@webfx/core-web';
import { useDocumentTitle, usePageView } from '@webfx/web-hooks';

import RevenueCloud from '../../assets/revenuecloudfx.svg';
import WebFX from '../../assets/webfx.svg';

import { SignInForm } from '../../components/SignInForm/SignInForm.jsx';
import { ForgotPasswordForm } from '../../components/ForgotPasswordForm/ForgotPasswordForm';

const SignInAnimation = React.lazy(() =>
  import('../../components/SignInAnimation/SignInAnimation')
);

import styles from './SignIn.module.css';

export function SignIn() {
  useDocumentTitle('Sign In | RevenueCloudFX');
  usePageView();

  const history = useHistory();
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const { search } = useLocation();

  useEffect(() => {
    if (search === '?password-reset' && showForgotPassword) {
      setShowForgotPassword(false);
    }
  }, [search, showForgotPassword, setShowForgotPassword]);

  return (
    <div className={classnames(styles.page)}>
      <div className={classnames(styles.form)}>
        <Snackbar className="snackbar bg-danger" hideCloseButton icon="warning" variant="error" />
        <div
          className={classnames([
            'd-flex flex-wrap justify-content-between align-items-center p-3 bold font-13 mb-4',
          ])}
        >
          <Link
            plain
            external={false}
            to="https://www.webfx.com/"
            className={classnames(styles.topLink, 'mx-auto mb-2')}
          >
            <IconLabel icon="keyboard_arrow_left" label="Return to WebFX.com" />
          </Link>
          <Link
            plain
            external={false}
            to="https://www.webfx.com/Free-Quote.php"
            className={classnames([
              'border border-primary rounded mx-auto mb-2 pl-4 pr-4 pt-2 pb-2 ',
              styles.topLink,
            ])}
          >
            <IconLabel
              icon="business_center"
              label="Get a Strategy & Proposal"
              labelClassName={classnames(styles.topLink)}
            />
          </Link>
        </div>
        <Snackbar className="snackbar" hideCloseButton icon="warning" variant="error" />
        {search === '?password-reset' ? (
          <div className="p-3">
            <Alert dismissible variant="success" onClose={() => history.push({ search: null })}>
              We&apos;ve sent a reset link your email. Please finalize your reset by clicking the
              link in your email.
            </Alert>
          </div>
        ) : null}

        {search === '?reset-successful' ? (
          <div className="p-3">
            <Alert dismissible variant="success" onClose={() => history.push({ search: null })}>
              Your password has been reset, please login below.
            </Alert>
          </div>
        ) : null}
        <Svg className="mx-auto mb-4" src={RevenueCloud} style={{ width: 223, height: 28 }} />

        {showForgotPassword === true ? (
          <ForgotPasswordForm onCancel={() => setShowForgotPassword(false)} />
        ) : (
          <SignInForm onClickForgot={() => setShowForgotPassword(true)} />
        )}

        <div
          className={classnames([
            'd-flex text-center justify-content-center',
            styles.poweredByWrapper,
          ])}
        >
          <Typography className="m-0" component="p" variant="p">
            Powered by
          </Typography>
          <a
            href="https://www.webfx.com/"
            className="ml-2 d-inline-block position-relative"
            style={{ top: 2 }}
          >
            <Svg src={WebFX} style={{ width: 68, height: 28 }} />
          </a>
        </div>
      </div>
      <div className={styles.animation}>
        <Suspense fallback={<div />}>
          <SignInAnimation />
        </Suspense>
      </div>
    </div>
  );
}
